/* .App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */
.green {
  color: green;
}

.red {
  color: red;
}

.content-wrapper {
  height: 100vh;
}

.box-container {
  box-shadow: 2px 2px 15px gray !important;
}

.coustom-w-50 {
  width: 55px !important;
}

.coustom-w-150 {
  width: 200px !important;
}
.coustom-w-80 {
  width: 70px !important;
}
.coustom-w-100 {
  width: 120px !important;
}
.height-22 {
  height: 22px !important;
  margin-top: 0.9px;
}
.custom-spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-text {
  font-size: 13px;
  color: rgb(90, 90, 90);
}
